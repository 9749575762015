<template>
  <div>
    <!-- begin:: teacher card -->
    <TeacherCountCard
      @card-clicked="goToTeacher"
      class="mr-4"
    ></TeacherCountCard>
    <!-- end:: teacher card -->
  </div>
</template>

<script>
import TeacherCountCard from "@/modules/dashboard/components/employee/teacher/TeacherCountCard";
export default {
  name: "TeacherCountDashboard",
  components: { TeacherCountCard },
  methods: {
    /**
     * Go to Teacher
     */
    goToTeacher() {
      this.$router.push({ name: "teacherActiveList" });
    },
  },
};
</script>

<style scoped></style>
