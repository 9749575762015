<template>
  <CommonAttendanceModal :title="title">
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
      :manageable="false"
      @changePage="handlePageChange"
      @expandDetails="handleExpand"
    ></EmployeeDailyAttendanceTable>

    <ClockRecordModal :is-busy="false" :records="records"></ClockRecordModal>
  </CommonAttendanceModal>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeDailyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeDailyAttendanceTable";
import CommonAttendanceModal from "@/modules/school/components/shared/employee/modal/CommonAttendanceModal";
import dayjs from "dayjs";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";

export default {
  name: "StaffAttendanceDashboardModal",
  components: {
    ClockRecordModal,
    CommonAttendanceModal,
    EmployeeDailyAttendanceTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    attStatusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      employee: {},
      attendance: {},
    };
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchStaffDashboardDailyAttendance", {
        id: this.attStatusId,
      });
    },

    handlePageChange(page) {
      this.$store.commit("setStaffDashboardDailyAttendancePage", page);
      this.fetch();
    },

    handleExpand(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Staff has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.$store
        .dispatch("fetchStaffClockRecord", {
          id: data.item.id,
          date: dayjs().format(),
        })
        .then(() => {
          this.$bvModal.show("clock-record-modal");
        });
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffDashboardDailyAttendanceList",
      pagination: "getStaffDashboardDailyAttendancePagination",
      isBusy: "getStaffDashboardDailyAttendanceBusy",
      records: "getStaffClockRecordList",
    }),
  },
};
</script>

<style scoped></style>
