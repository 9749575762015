<template>
  <b-modal
    id="clock-record-modal"
    title-class="h4 font-weight-bold"
    :title="$t('CLOCKPAGE.HEADER')"
    size="lg"
    scrollable
    lazy
    footer-bg-variant="light"
  >
    <div class="d-flex justify-content-center">
      <ClockRecord :is-busy="isBusy" :clock-records="records"></ClockRecord>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="light" class="btn-hover-danger" @click="cancel">
        {{ $t("BUTTON.CANCEL") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClockRecord from "@/modules/core/components/attendance/ClockRecord";

export default {
  name: "ClockRecordModal",
  components: { ClockRecord },
  props: {
    records: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style scoped></style>
