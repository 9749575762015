<template>
  <div>
    <!-- begin::Dashboard -->
    <div class="d-flex flex-column">
      <b-card class="text-primary flex-grow-1 card-custom">
        <h2>Welcome back, {{ profile.name }}</h2>
        <h5>
          Is in <strong>{{ profile.organisation }}</strong>
        </h5>
      </b-card>

      <div v-if="isAdmin">
        <h6 class="my-5">Teacher</h6>
        <div class="d-flex flex-column flex-md-row">
          <TeacherCountDashboard class="mt-1"></TeacherCountDashboard>
          <TeacherAttendanceCountDashboard
            class="mt-1"
          ></TeacherAttendanceCountDashboard>
        </div>

        <h6 class="my-5">Staff</h6>
        <div class="d-flex flex-column flex-md-row">
          <StaffCountDashboard class="mt-1"></StaffCountDashboard>
          <StaffAttendanceCountDashboard
            class="mt-1"
          ></StaffAttendanceCountDashboard>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Dashboard -->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import TeacherAttendanceCountDashboard from "@/modules/dashboard/components/attendance/teacher/TeacherAttendanceCountDashboard";
import StaffAttendanceCountDashboard from "@/modules/dashboard/components/attendance/staff/StaffAttendanceCountDashboard";
import TeacherCountDashboard from "@/modules/dashboard/components/employee/teacher/TeacherCountDashboard";
import StaffCountDashboard from "@/modules/dashboard/components/employee/staff/StaffCountDashboard";

export default {
  name: "dashboard",
  components: {
    StaffCountDashboard,
    TeacherCountDashboard,
    StaffAttendanceCountDashboard,
    TeacherAttendanceCountDashboard,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.DASHBOARD") },
    ]);
  },
  methods: {},
  computed: {
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
    ...mapGetters({
      isAdmin: "getAdminPermission",
    }),
  },
};
</script>
