<template>
  <div>
    <AppLoader v-if="isBusy"></AppLoader>

    <!-- begin: time-line    -->
    <div class="timeline timeline-3" v-if="!isBusy">
      <div class="timeline-items">
        <div
          class="timeline-item"
          v-for="clock in clockRecords"
          :key="clock.id"
        >
          <!--begin::Icon-->
          <div class="timeline-media">
            <span class="svg-icon svg-icon-md svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </div>
          <!--end::Icon-->

          <!--begin::Info-->
          <div class="timeline-content">
            <span class="font-weight-bolder">{{ clock.clock_in }}</span>
            <span class="text-muted ml-2">{{
              clock.remark != null ? clock.remark : "Today"
            }}</span>
            <p class="font-weight-normal text-dark-50 pb-2">
              {{ clock.type.name }}
            </p>
            <p v-if="clock.location">
              {{ clock.location.name }}
            </p>
            <p class="font-weight-bolder" v-if="clock.temperature">
              {{ clock.temperature.temp + "\xB0C" }}
            </p>
            <p v-if="clock.facial_device">
              {{ clock.facial_device.device_name }}
            </p>
          </div>
          <!--end::Info-->
        </div>
      </div>
    </div>
    <!-- end: time-line    -->

    <!-- begin:: when clock's null  -->
    <div
      class="d-flex flex-column justify-content-center"
      v-if="!clockRecords.length"
    >
      <div class="symbol symbol-150 symbol-light align-self-center">
        <span class="symbol-label rounded-circle">
          <span class="svg-icon svg-icon-9x">
            <inline-svg
              src="/media/svg/illustrations/null/empty_2.svg"
            ></inline-svg>
          </span>
        </span>
      </div>

      <div class="align-self-center">
        <h5 class="font-weight-bold mt-5 text-center">
          {{ $t("CLOCKPAGE.RECORDS") }}
        </h5>
        <p class="text-center text-muted text-h6">
          {{ $t("CLOCKPAGE.RECORDS2") }}
        </p>
      </div>
    </div>
    <!-- end:: when clock's null  -->
  </div>
</template>

<script>
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "ClockRecord",
  components: { AppLoader },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    clockRecords: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
