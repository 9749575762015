<template>
  <b-card class="card-custom" v-on:click="$emit('card-clicked')">
    <div class="d-flex">
      <span class="svg-icon svg-icon-4x svg-icon-primary">
        <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
      </span>

      <div class="d-flex flex-column">
        <h1 class="ml-5 font-weight-bolder text-dark">
          {{ staff }}
        </h1>
        <span class="ml-5 text-muted">
          {{ $t("DASHBOARD.STAFF.SUBTITLE") }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StaffCountCard",
  mounted() {
    this.$store.dispatch("fetchStaffDashboardCount");
  },
  computed: {
    ...mapGetters({
      staff: "getStaffDashboardCount",
    }),
  },
};
</script>

<style scoped></style>
