<template>
  <div>
    <div v-if="!isBusy" class="d-flex flex-column flex-md-row">
      <StaffAttendanceCountCard
        title="Present"
        :count="counts.presentCount"
        @card-clicked="goToPresent"
        class="mr-6 mb-5 mb-lg-0"
      ></StaffAttendanceCountCard>

      <StaffAttendanceCountCard
        title="Absent"
        :count="counts.absentCount"
        @card-clicked="goToAbsent"
        class="mr-6 mb-5 mb-lg-0"
      ></StaffAttendanceCountCard>

      <StaffAttendanceCountCard
        title="Leave"
        :count="counts.leaveCount"
        @card-clicked="goToLeave"
        class="mr-6 mb-5 mb-lg-0"
      ></StaffAttendanceCountCard>

      <StaffAttendanceCountCard
        title="Away"
        :count="counts.awayCount"
        @card-clicked="goToAway"
        class="mr-6"
      ></StaffAttendanceCountCard>
    </div>
    <AppLoader v-else></AppLoader>
    <StaffAttendanceDashboardModal
      :title="title"
      :att-status-id="id"
    ></StaffAttendanceDashboardModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StaffAttendanceCountCard from "@/modules/dashboard/components/attendance/staff/StaffAttendanceCountCard";
import StaffAttendanceDashboardModal from "@/modules/dashboard/components/attendance/staff/StaffAttendanceDashboardModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "StaffAttendanceCountDashboard",
  components: {
    AppLoader,
    StaffAttendanceDashboardModal,
    StaffAttendanceCountCard,
  },
  data() {
    return {
      title: "Title",
      id: 0,
      isBusy: false,
    };
  },
  mounted() {
    this.isBusy = true;
    this.$store.dispatch("fetchStaffDashboardCount");
    this.$store
      .dispatch("fetchStaffDashboardDailyAttendanceCount")
      .finally(() => (this.isBusy = false));
  },
  methods: {
    fetch() {
      this.$store.commit("setStaffDashboardDailyAttendancePage", 1);
      this.$store.dispatch("fetchStaffDashboardDailyAttendance", {
        id: this.id,
      });
    },

    goToPresent() {
      this.title = "Staff Present Daily Attendance List";
      this.id = 1;
      this.fetch();

      this.$bvModal.show("common-attendance-modal");
    },

    goToAbsent() {
      this.title = "Staff Absent Daily Attendance List";
      this.id = 2;
      this.fetch();

      this.$bvModal.show("common-attendance-modal");
    },

    goToLeave() {
      this.title = "Staff Leave Daily Attendance List";
      this.id = 3;
      this.fetch();

      this.$bvModal.show("common-attendance-modal");
    },

    goToAway() {
      this.title = "Staff Away Daily Attendance List";
      this.id = 4;
      this.fetch();

      this.$bvModal.show("common-attendance-modal");
    },
  },
  computed: {
    ...mapGetters({
      counts: "getStaffDashboardDailyAttendanceCount",
    }),
  },
};
</script>

<style scoped></style>
