<template>
  <div class="d-flex flex-row justify-content-right">
    <!-- begin: Device Users Modal Button         -->
    <button
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="showClockRecord(data)"
    >
      <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
        <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
      </span>
    </button>
    <button
      v-if="manageable"
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="showManageModal(data)"
    >
      <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
        <inline-svg src="/media/svg/icons/Communication/Group.svg" />
      </span>
    </button>
    <button
      v-if="manageable"
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="showUpdateModal(data)"
    >
      <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
        <inline-svg src="/media/svg/icons/Home/Clock.svg" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "EmployeeDailyAttendanceActions",
  props: {
    data: {
      type: Object,
      required: true,
    },
    manageable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    showClockRecord(row) {
      console.log("Emit employee details clicked");
      this.$emit("expandDetails", row);
    },

    showManageModal(row) {
      console.log("Emit Show Manage Modal");
      this.$emit("showManageModal", row);
    },

    showUpdateModal(row) {
      console.log("Emit Show Manage Modal");
      this.$emit("showUpdateModal", row);
    },
  },
};
</script>

<style scoped></style>
