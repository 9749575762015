<template>
  <CommonEmployeeDetailsModal :title="title">
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
      :manageable="false"
      @changePage="handlePageChange"
      @expandDetails="handleExpand"
    ></EmployeeDailyAttendanceTable>

    <ClockRecordModal :is-busy="false" :records="records"></ClockRecordModal>
  </CommonEmployeeDetailsModal>
</template>

<script>
import CommonEmployeeDetailsModal from "@/modules/school/components/shared/employee/modal/CommonEmployeeDetailsModal";
import { mapGetters } from "vuex";
import EmployeeDailyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeDailyAttendanceTable";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import dayjs from "dayjs";
export default {
  name: "TeacherAttendanceDashboardModal",
  components: {
    EmployeeDailyAttendanceTable,
    CommonEmployeeDetailsModal,
    ClockRecordModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    attStatusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      recordIsBusy: false,
      attendance: {},
      employee: {},
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherDashboardDailyAttendanceList", {
          id: this.attStatusId,
        })
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      this.$store.commit("setTeacherDashboardDailyAttendancePage", page);
      this.fetch();
    },

    handleExpand(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Staff has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.recordIsBusy = true;
      this.$bvModal.show("clock-record-modal");

      this.$store
        .dispatch("fetchTeacherClockRecordList", {
          id: data.item.id,
          date: dayjs().format(),
        })
        .then(() => {})
        .finally(() => (this.recordIsBusy = false));
    },

    showManageModal(data) {
      console.log(data);

      this.employee = data.item;
      console.log("Employee", this.employee);

      // Create temp. attendance
      if (data.item.attendance === undefined || data.item.attendance === null) {
        console.log("Assign empty attendance");
        data.item.attendance = {
          id: null,
          date: dayjs().format(),
        };
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-manage-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherDashboardDailyAttendanceList",
      pagination: "getTeacherDashboardDailyAttendancePagination",
      isBusy: "getTeacherDashboardDailyAttendanceBusy",
      records: "getTeacherClockRecordList",
    }),
  },
};
</script>

<style scoped></style>
