<template>
  <div>
    <!-- begin:: staff card -->
    <StaffCountCard
      @card-clicked="goToStaff"
      class="mr-4 mb-5 mb-lg-0"
    ></StaffCountCard>
    <!-- end:: staff card -->
  </div>
</template>

<script>
import StaffCountCard from "@/modules/dashboard/components/employee/staff/StaffCountCard";
export default {
  name: "StaffCountDashboard",
  components: { StaffCountCard },
  methods: {
    /**
     * Go to Staff
     */
    goToStaff() {
      this.$router.push({ name: "staffActiveList" });
    },
  },
};
</script>

<style scoped></style>
